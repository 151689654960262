import RouterFactory from 'pk/factory/RouterFactory'
import LocalCacheService from 'pk/service/LocalCacheService'
import WXService from 'pk/service/WXService'
import { appEnv } from 'pk/service/Tools/ToolsService'
import { Router } from 'vue-router'
import UserService from './User/UserService'

class RouterService extends RouterFactory {
  init (router: Router) {
    this.router = router
    this.router.beforeEach((to, from, next) => {
      if (appEnv === 'dev') {
        document.title = 'demo'
      } else {
        document.title = to.meta.title as string
      }
      if (appEnv === 'dev') {
        next()
        return
      }
      if (to.meta.isDisableAuth) {
        next()
      } else if (to.meta.isAuth) {
        return Promise.resolve()
          .then(() => {
            if (!UserService.isLogin()) {
              return UserService.login()
            }
          })
          .then(() => {
            if (!UserService.info._isWxAuth) {
              return WXService.auth('snsapi_userinfo')
            }
          })
          .then(() => {
            next()
          })
      } else {
        if (!(UserService.isLogin() || LocalCacheService.wx.get('openid'))) {
          WXService.auth()
            .then(() => {
              next()
            })
        } else {
          next()
        }
      }
    })
  }
}

export default new RouterService()
