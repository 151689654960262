import wx from 'wx-jssdk-ts'
import { appEnv, appHost, isAndroid, isPCWX, isWX } from 'pk/service/Tools/ToolsService'
import IOService from './IOService'
import UIService from './UIService'
import RouterFactory from 'pk/factory/RouterFactory'
import LocalCacheService from 'pk/service/LocalCacheService'

interface ShareParams {
  title: string;
  desc: string;
  link: string;
  imgUrl?: string;
}

interface IchooseWXPay {
  timestamp: number; // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
  nonceStr: string; // 支付签名随机串，不长于 32 位
  package: string; // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
  signType: string; // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
  paySign: string; // 支付签名
  // 支付成功后的回调函数
  success(res: any): void;
}

class WXService {
  isInitConfig = false
  RouterService!: RouterFactory

  constructor () {
    this.RouterService = new RouterFactory()
  }

  init () {
    return this.config()
  }

  config () {
    return new Promise<void>((resolve) => {
      if (isWX && appEnv !== 'dev' && (this.isInitConfig === false || isAndroid)) {
        return IOService.get('/wechat/getConfig', {
          url: location.href
        })
          .then((res) => {
            wx.ready(() => {
              this.isInitConfig = true
              resolve()
            })
            wx.config(res.data)
          })
      } else {
        resolve()
      }
    })
  }

  auth (scope = 'snsapi_base') {
    return Promise.resolve()
      .then(() => {
        if (isWX && appEnv !== 'dev') {
          return IOService.post('/wechat/authOA', { redirect_url: location.href, scope })
            .then((res) => {
              location.href = res.data.url
            })
        }
      })
  }

  login () {
    return IOService.post('/wechat/loginOA', { wxCode: this.RouterService.params('code'), scope: this.RouterService.query('scope') })
      .then((res) => {
        LocalCacheService.wx.set('openid', res.data.openid)
        return res
      })
  }

  chooseWXPay (params: IchooseWXPay & { mweb_url: string }, payment = 'JSAPI') {
    return Promise.resolve()
      .then(() => {
        if (payment === 'JSAPI') {
          return this.payJSAPI(params)
        } else {
          return this.payMWEB(params.mweb_url)
        }
      })
  }

  private payJSAPI (params: IchooseWXPay) {
    return this.config()
      .then(() => {
        return new Promise<void>((resolve, reject) => {
          if (isWX) {
            wx.chooseWXPay({
              ...params,
              success: (res) => {
                resolve(res)
              },
              fail: (res) => {
                reject(res)
              },
              cancel: () => {
                const err = { status: 'cancel-pay', message: '取消支付' }
                reject(err)
              }
            })
          } else {
            resolve()
          }
        })
      })
  }

  private payMWEB (url: string) {
    return new Promise<void>((resolve) => {
      location.href = url
      setTimeout(() => {
        UIService.confirm('是否已支付完成', {
          confirmButtonText: '已完成',
          cancelButtonText: '未完成'
        })
          .then(() => {
            resolve()
          })
          .catch(() => {
            resolve()
          })
      }, 2000)
    })
  }

  checkPayOrder (callback: Function) {
    const queryNum = 3
    let queriedNum = 0
    let loading!: any
    const check = (intervalTime: number) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          callback()
            .then((res: any) => {
              resolve(res)
            })
            .catch((err: any) => {
              reject(err)
            })
        }, intervalTime)
      })
    }

    const keepCheck = (): Promise<any> => {
      queriedNum++
      if (queriedNum === 1) {
        loading = UIService.toast.loading('订单验证中...')
      }
      return check(queriedNum > 1 ? 1000 : 0)
        .then((res) => {
          loading.clear()
          return res
        })
        .catch(() => {
          if (queriedNum === queryNum) {
            loading.clear()
            const err = { message: '支付失败' }
            return Promise.reject(err)
          }
          return keepCheck()
        })
    }

    return keepCheck()
  }

  updateShareData (params: ShareParams) {
    params.imgUrl = params.imgUrl || '/images/logo.png'
    params.imgUrl = appHost + params.imgUrl
    if (!params.link.includes('://')) {
      params.link = appHost + '/?v=1#' + params.link
    }
    if (params.link.includes('#')) {
      if (params.link.split('#')[1].includes('?')) {
        params.link += '&_source=share'
      } else {
        params.link += '?_source=share'
      }
    } else {
      if (params.link.includes('?')) {
        params.link += '&_source=share'
      } else {
        params.link += '?_source=share'
      }
    }
    return Promise.resolve()
      .then(() => {
        if (isWX && appEnv !== 'dev') {
          return this.config()
            .then(() => {
              this.hideShareMenu()
              if (isPCWX) {
                return this.onMenuShareAppMessage(params)
              } else {
                return this.updateTimelineShareData(params)
                  .then(() => this.updateAppMessageShareData(params))
              }
            })
            .catch((err) => {
              window.console.error(err)
            })
        }
      })
      .then((res) => {
        this.showShareMenu()
        return Promise.resolve(res)
      })
  }

  private updateTimelineShareData (params: ShareParams) {
    let isResolve = false
    return new Promise<void|string>((resolve, reject) => {
      wx.updateTimelineShareData({
        title: params.title,
        link: params.link,
        imgUrl: params.imgUrl as string,
        success: () => {
          isResolve = true
          resolve()
        },
        cancel: () => {
          isResolve = true
          resolve('朋友圈分享取消')
        },
        fail: (err) => {
          reject(err)
        }
      })
      setTimeout(() => {
        if (isResolve === false) {
          resolve()
        }
      }, 2000)
    })
  }

  private updateAppMessageShareData (params: ShareParams) {
    let isResolve = false
    return new Promise<void|string>((resolve, reject) => {
      wx.updateAppMessageShareData({
        title: params.title, // 分享标题
        desc: params.desc, // 分享描述
        link: params.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: params.imgUrl as string, // 分享图标
        success: () => {
          isResolve = true
          resolve()
        },
        cancel: () => {
          isResolve = true
          resolve('好友分享取消')
        },
        fail: (err) => {
          reject(err)
        }
      })
      setTimeout(() => {
        if (isResolve === false) {
          resolve()
        }
      }, 2000)
    })
  }

  private onMenuShareAppMessage (params: ShareParams) {
    let isResolve = false
    return new Promise<void|string>((resolve, reject) => {
      wx.onMenuShareAppMessage({
        title: params.title,
        desc: params.desc,
        link: params.link,
        imgUrl: params.imgUrl as string,
        success: () => {
          isResolve = true
          resolve()
        },
        cancel: () => {
          isResolve = true
          resolve('old好友分享取消')
        },
        fail: (err) => {
          reject(err)
        }
      })
      setTimeout(() => {
        if (isResolve === false) {
          resolve()
        }
      }, 2000)
    })
  }

  public hideMenuItems (menuList: any[]) {
    wx.hideMenuItems({
      menuList // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
    })
  }

  public showMenuItems (menuList: any[]) {
    wx.showMenuItems({
      menuList // 要显示的菜单项，所有menu项见附录3
    })
  }

  public hideShareMenu () {
    this.hideMenuItems([
      'menuItem:share:appMessage',
      'menuItem:share:timeline',
      'menuItem:share:qq',
      'menuItem:share:weiboApp',
      'menuItem:favorite',
      'menuItem:share:facebook',
      'menuItem:share:QZone'
    ])
  }

  public showShareMenu () {
    this.showMenuItems([
      'menuItem:share:appMessage',
      'menuItem:share:timeline',
      'menuItem:share:qq',
      'menuItem:share:weiboApp',
      'menuItem:favorite',
      'menuItem:share:facebook',
      'menuItem:share:QZone'
    ])
  }
}

export default new WXService()
