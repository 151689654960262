import { defineComponent } from 'vue';
import FooterTabBar from '@/components/FooterTabBar.vue';
import FooterTabBarService from '@/service/FooterTabBarService';
import IndustryService from '@/service/Industry/IndustryService';
import NotifyService from '@/service/Notify/NotifyService';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import eruda from 'eruda'
import UserService from '@/service/User/UserService';
import RouterService from '@/service/RouterService';
export default defineComponent({
  components: {
    FooterTabBar
  },
  props: {
    load: {
      type: Function,
      default: () => Promise.resolve()
    }
  },
  data() {
    return {
      clickCount: 0,
      lastClickTime: 0,
      interval: 200,
      triggerCount: 8 // 触发debug点击次数
    };
  },

  provide() {
    return {
      PageContainer: () => this
    };
  },
  computed: {
    isShowFooterTabBar() {
      return FooterTabBarService.checkShow();
    }
  },
  methods: {
    handleLoad() {
      return IndustryService.index().then(() => this.load()).then(() => {
        if (!RouterService.meta('isDisableAuth')) {
          UserService.todayFirstLogin();
        }
        NotifyService.checkQueryNotify().then(() => NotifyService.getUnreadCount());
      });
    },
    reload() {
      return this.$refs.drElement.reload();
    },
    handleLoadSuccess() {
      this.$emit('success');
    },
    handleTouchEnd() {
      const current = new Date().getTime();
      if (this.lastClickTime === 0 || current - this.lastClickTime < this.interval) {
        this._countUp(current);
      } else {
        this._reset(1);
      }
      if (this.clickCount === this.triggerCount) {
        // eruda.init()
      }
    },
    _countUp(current) {
      this.clickCount++;
      this.lastClickTime = current;
    },
    _reset(count = 0) {
      this.clickCount = count;
      this.lastClickTime = 0;
    }
  }
});