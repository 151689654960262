import hr from './user/hr'
import sd from './user/sd'

export default [
  {
    path: '/user',
    component: () => import('@/views/user/index.vue'),
    meta: {
      title: '个人中心'
    }
  },
  {
    path: '/user/enterprise/edit',
    component: () => import('@/views/user/enterprise/edit.vue'),
    meta: {
      title: '编辑企业信息',
      isAuth: true
    }
  },
  {
    path: '/user/personal/edit',
    component: () => import('@/views/user/personal/edit.vue'),
    meta: {
      title: '编辑个人信息',
      isAuth: true
    }
  },
  {
    path: '/user/personal/resume',
    component: () => import('@/views/user/personal/resume.vue'),
    meta: {
      title: '完善个人简历',
      isAuth: true
    }
  },
  {
    path: '/user/auth',
    component: () => import('@/views/user/auth.vue'),
    meta: {
      title: '认证',
      isAuth: true
    }
  },
  {
    path: '/user/personal/auth',
    component: () => import('@/views/user/personal/auth.vue'),
    meta: {
      title: '个人认证',
      isAuth: true
    }
  },
  {
    path: '/user/enterprise/auth',
    component: () => import('@/views/user/enterprise/auth.vue'),
    meta: {
      title: '企业认证',
      isAuth: true
    }
  },
  {
    path: '/user/coupon',
    component: () => import('@/views/user/coupon/index.vue'),
    meta: {
      title: '我的互助券'
    }
  },
  {
    path: '/user/task',
    component: () => import('@/views/user/task/index.vue'),
    meta: {
      title: '互助任务'
    }
  },
  {
    path: '/user/order',
    component: () => import('@/views/user/order/index.vue'),
    meta: {
      title: '联系记录'
    }
  },
  {
    path: '/user/setup',
    component: () => import('@/views/user/setup/index.vue'),
    meta: {
      title: '设置',
      isAuth: true
    }
  },
  {
    path: '/user/invite',
    component: () => import('@/views/user/invite/index.vue'),
    meta: {
      title: '邀请'
    }
  },
  {
    path: '/user/invite/record',
    component: () => import('@/views/user/invite/record.vue'),
    meta: {
      title: '邀请记录'
    }
  },
  {
    path: '/user/share',
    component: () => import('@/views/user/share/index.vue'),
    meta: {
      title: '分享记录'
    }
  },
  {
    path: '/user/notify',
    component: () => import('@/views/user/notify/index.vue'),
    meta: {
      title: '通知记录'
    }
  },
  ...hr,
  ...sd
]
