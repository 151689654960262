import BaseAbstract from 'pk/abstract/BaseAbstract'
import InfoActionService from '@/service/Info/InfoActionService'
import RouterService from '@/service/RouterService'

export default class InfoBaseAbstract extends BaseAbstract {
  public view () {
    return InfoActionService.view({ _model: this.modelName, info_id: parseInt(RouterService.query('id')), su: RouterService.query('su') })
  }

  public isHR () {
    return this.modelName === 'HrJob' || this.modelName === 'HrResume'
  }

  public isSD () {
    return this.modelName === 'SdSupply' || this.modelName === 'SdDemand'
  }
}
