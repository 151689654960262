import InfoBaseAbstract from '@/abstract/InfoBaseAbstract'
import IOService from 'pk/service/IOService'

class SdDemandService extends InfoBaseAbstract {
  modelName = 'SdDemand'
  displayName = '需求'
  basePath = '/sd/demand'

  index (params: any) {
    return IOService.get('/sd_demand', params)
  }

  show (id: number) {
    return IOService.get(`/sd_demand/${id}`)
  }

  destroy (id: number) {
    return IOService.delete(`/sd_demand/${id}`)
  }

  query (params: { page?: number; id?: number }) {
    return IOService.get('/sd_demand/query', params)
  }
}

export default new SdDemandService()
