import RouterService from '@/service/RouterService'

class FooterTabBarService {
  active = null as number|null
  private whiteList = [
    { path: '/', active: 0 },
    { path: '/hr', active: 1 },
    { path: '/sd', active: 3 },
    { path: '/user', active: 4 }
  ]

  constructor () {
    this.init()
  }

  public init () {
    const item = this.whiteList.find((res) => RouterService.getPath() === res.path)
    if (item) {
      this.active = item.active
    }
  }

  public checkShow () {
    let result = this.whiteList.some((res) => res.path === RouterService.getPath())
    if (!result) {
      if (RouterService.getPath() === '/') {
        result = true
      } else if (location.href.includes('source') && (location.href.includes('share') || location.href.includes('notify'))) {
        result = true
      }
    }
    return result
  }
}

export default new FooterTabBarService()
