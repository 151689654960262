export default [
  {
    path: '/sd',
    component: () => import('@/views/sd/index.vue'),
    meta: {
      title: '供需列表'
    }
  },
  {
    path: '/sd/demand/show',
    component: () => import('@/views/sd/demand/show.vue'),
    meta: {
      title: '需求详情'
    }
  },
  {
    path: '/sd/supply/show',
    component: () => import('@/views/sd/supply/show.vue'),
    meta: {
      title: '供应详情'
    }
  }
]
