import { defineComponent } from 'vue';
import { Tabbar, TabbarItem } from 'vant';
import PopupPublishService from './Popup/PopupPublishService';
import UIService from 'pk/service/UIService';
import FooterTabBarService from '@/service/FooterTabBarService';
import NotifyService from '@/service/Notify/NotifyService';
export default defineComponent({
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  data() {
    return {
      PopupPublishService: new PopupPublishService(),
      FooterTabBarService
    };
  },
  watch: {
    '$route'() {
      FooterTabBarService.init();
      this.drElement.reload();
    }
  },
  computed: {
    badge() {
      return NotifyService.unreadCount || null;
    },
    drElement() {
      return this.$refs.drElement;
    }
  },
  created() {
    FooterTabBarService.init();
  },
  methods: {
    handleAction() {
      return this.PopupPublishService.open().catch(err => UIService.toast.bizFail(err));
    }
  }
});