import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import hr from './hr'
import sd from './sd'
import user from './user'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/home.vue'),
    meta: {
      title: '首页-原草互助'
    }
  },
  {
    path: '/wechat-login',
    component: () => import('@/views/wechat-login.vue'),
    meta: {
      title: '加载中...',
      isDisableAuth: true
    }
  },
  {
    path: '/help',
    component: () => import('@/views/help/index.vue'),
    meta: {
      title: '帮助'
    }
  },
  {
    path: '/operation/invite',
    component: () => import('@/views/operation/invite.vue'),
    meta: {
      title: '邀请加入'
    }
  },
  {
    path: '/operation/publish',
    component: () => import('@/views/operation/publish.vue'),
    meta: {
      title: '发布'
    }
  },
  ...user,
  ...hr,
  ...sd
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
