import axios from 'axios'
import { appApi, isWX } from 'pk/service/Tools/ToolsService'
import VersionService, { Version } from './VersionService'
import UIService from './UIService'
import WXService from './WXService'
import LocalCacheService from 'pk/service/LocalCacheService'
import StatusFactory from 'pk/factory/StatusFactory'
import RouterFactory from 'pk/factory/RouterFactory'
export interface PromiseResult {
  message: string;
  data: any;
  code?: number;
  status?: string;
  version: Version;
  isQuiet?: boolean;
}

interface UrlParams {
  url: string;
  method: string;
  params?: object;
  data?: object;
}

export class IOService {
  onceUrls = ['/app/getAppConfig']
  appApi = appApi + '/api'
  StatusService!: StatusFactory
  RouterService!: RouterFactory
  checkLogin!: Function // 外部填充的

  constructor () {
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    axios.defaults.headers.common.Accept = 'application/json'
    this.StatusService = new StatusFactory()
    this.RouterService = new RouterFactory()
  }

  private ajax (data: any): Promise<PromiseResult> {
    axios.defaults.baseURL = this.appApi
    if (LocalCacheService.auth.get('api_token')) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + LocalCacheService.auth.get('api_token')
    }
    data.data = data.data || {}
    data.data.wxOpenid = data.data.wxOpenid || LocalCacheService.wx.get('openid')
    data.data._platform = isWX ? 'oa' : 'h5'

    return new Promise<PromiseResult>((resolve, reject) => {
      axios(data)
        .then(res => res.data)
        .then((res: PromiseResult) => {
          if (res.status === 'success') {
            res.data = res.data || {}
            // 如果本地有token但是失效了，然后直接走login接口，这个时候login方法还没有结束，token还没有更新，会直接走下面的check方法
            // 如果接口返回有api_token这个属性则一律更新token，这个需要观察下
            if (res.data && res.data.api_token) {
              LocalCacheService.auth.set('api_token', res.data.api_token)
            }
            return this.checkVersion(data, res)
              .then(() => {
                resolve(res)
              })
          } else {
            if (res.code === this.StatusService.weChatAuthFail) {
              UIService.toast.fail(res.message)
              setTimeout(() => {
                WXService.auth()
              }, 2000)
            }
            reject(res)
          }
        })
        .catch(res => {
          if (res.response) {
            reject(res.response.data)
          } else {
            reject(new Error('网络错误'))
          }
        })
    })
      .catch((err) => {
        if (err.message === 'Unauthenticated.') {
          err.code = 401
          err.status = 'error'
          err.data = {}
          err.message = '请先登录'
        }
        if (err.code === 401 || err.code === this.StatusService.emptyPhone) {
          return this.checkLogin(data.url)
            .then(() => this.ajax(data))
            .then((res: PromiseResult) => {
              if (res) {
                return Promise.resolve(res)
              } else {
                return Promise.reject(err)
              }
            })
            .catch(() => Promise.reject(err))
        }
        return Promise.reject(err)
      })
  }

  private checkVersion (data: UrlParams, res: PromiseResult) {
    return Promise.resolve()
      .then(() => {
        if (!this.onceUrls.includes(data.url)) {
          return VersionService.checkAllVersion(res.version)
        }
      })
  }

  get (url: string, data?: object) {
    return this.ajax({
      url,
      method: 'get',
      params: data
    })
  }

  post (url: string, data: object) {
    return this.ajax({
      url,
      method: 'post',
      data
    })
  }

  put (url: string, data: object) {
    return this.ajax({
      url,
      method: 'put',
      data
    })
  }

  delete (url: string, data?: object) {
    return this.ajax({
      url,
      method: 'delete',
      data
    })
  }

  upload (url: string, data: object) {
    return this.ajax({
      url,
      method: 'post',
      data,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }
}

export default new IOService()
