const ua = window.navigator.userAgent
export const isWX = /MicroMessenger/ig.test(ua)
export const isWXDev = /wechatdevtools/ig.test(ua)
export const isIOS = /iP(ad|hone|od)/.test(ua)
export const isAndroid = /(android|adr)/ig.test(ua)
export const isPCWX = /WindowsWechat|MacWechat/ig.test(ua)
export const isPC = !isIOS && !isAndroid
export const isMobile = isIOS || isAndroid
export const appEnv = location.host.includes('m-test.') ? 'test' : ((location.host.includes('m.') || location.host.includes('www.') || location.host.split('.').length === 2) ? 'prod' : 'dev')
export const appApi = getApi(location.hostname)
export const appHost = `${location.protocol}//${location.hostname}`

function getApi (host: string, subDomain = 'api') {
  const topDomain = host.split('.').slice(-2).join('.')
  const env = appEnv === 'prod' ? '' : appEnv
  subDomain = env ? `${subDomain}-${env}` : subDomain
  return `${location.protocol}//${subDomain}.${topDomain}`
}

/**
 * 格式化日期
 * @param value
 * @param fmt Y-m-d/Y-m-d H:i/Y-m
 */
export function formatDate (value?: string | Date | number, fmt = 'Y-m-d') {
  let date!: Date
  if (value) {
    if (typeof value === 'string') {
      value = value.replace(/-/g, '/')
      date = new Date(value)
    } else if (typeof value === 'number') {
      date = new Date(value)
    } else {
      date = value
    }
  } else {
    date = new Date()
  }

  const year = date.getFullYear()
  let month: string = (date.getMonth() + 1) + ''
  let day: string = date.getDate() + ''
  let hours: string = date.getHours() + ''
  let minutes: string = date.getMinutes() + ''
  let seconds: string = date.getSeconds() + ''
  if (month.length === 1) {
    month = '0' + month
  }
  if (day.length === 1) {
    day = '0' + day
  }
  if (hours.length === 1) {
    hours = '0' + hours
  }
  if (minutes.length === 1) {
    minutes = '0' + minutes
  }
  if (seconds.length === 1) {
    seconds = '0' + seconds
  }
  if (fmt === 'Y-m-d H:i') {
    return `${year}-${month}-${day} ${hours}:${minutes}`
  } else if (fmt === 'Y-m') {
    return `${year}-${month}`
  } else if (fmt === 'Y-m-d H:i:s') {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  } else {
    return `${year}-${month}-${day}`
  }
}

// 根据最后一级的id获取整个path
export function getSelectedPaths (id: number, data: any[]) {
  const paths: any[] = []
  function recursiveQuery (id: number) {
    const item = data.find((res: any) => res.id === id)
    if (item) {
      paths.unshift(item)
      if (item.parent_id) {
        recursiveQuery(item.parent_id)
      }
    }
  }
  recursiveQuery(id)
  return paths
}

// 大驼峰转下划线分割
export function toLowerLine (str: string) {
  let temp = str.replace(/[A-Z]/g, function (match) {
    return '_' + match.toLowerCase()
  })
  if (temp.slice(0, 1) === '_') { // 如果首字母是大写，执行replace时会多一个_，这里需要去掉
    temp = temp.slice(1)
  }
  return temp
}

export function phoneDesensitization (phone: string) {
  if (!phone) return '--'
  return phone.replace(/(\d{3})\d*(\d{4})/, '$1****$2')
}

export function wechatDesensitization (wechat: string) {
  if (!wechat) return '--'
  return wechat.replace(/(.{2}).*(.{2})/, '$1***$2')
}

function getDateTimeStamp (dateStr: string) {
  return Date.parse(dateStr.replace(/-/gi, '/'))
}

export function getSecondDiff (dateStr: string) {
  const publishTime = getDateTimeStamp(dateStr)
  const timeNow = new Date().getTime()
  return timeNow - publishTime
}

export function getDateDiff (dateStr: string) {
  const publishTime = getDateTimeStamp(dateStr) / 1000
  const timeNow = Math.floor(new Date().getTime() / 1000)
  const date = new Date(publishTime * 1000)
  let M: string | number = date.getMonth() + 1
  let D: string | number = date.getDate()
  let H: string | number = date.getHours()
  let m: string | number = date.getMinutes()
  let s: string | number = date.getSeconds()
  if (M < 10) {
    M = '0' + M
  }
  if (D < 10) {
    D = '0' + D
  }
  if (H < 10) {
    H = '0' + H
  }
  if (m < 10) {
    m = '0' + m
  }
  if (s < 10) {
    s = '0' + s
  }

  const d = timeNow - publishTime
  const d_days = Math.floor(d / 86400)
  const d_hours = Math.floor(d / 3600)
  const d_minutes = Math.floor(d / 60)
  const d_seconds = Math.floor(d)

  if (d_days > 0 && d_days < 30) {
    return d_days + '天前'
  } else if (d_days <= 0 && d_hours > 0) {
    return d_hours + '小时前'
  } else if (d_hours <= 0 && d_minutes > 0) {
    return d_minutes + '分钟前'
  } else if (d_seconds < 60) {
    if (d_seconds <= 0) {
      return '刚刚'
    } else {
      return d_seconds + '秒前'
    }
  } else if (d_days >= 30) {
    return M + '-' + D
  }
}

export function getPayment () {
  if (isWX) {
    return 'JSAPI'
  } else if (isMobile) {
    return 'MWEB'
  } else if (isPC) {
    return ''
  } else {
    return ''
  }
}
