import "core-js/modules/es.array.push.js";
import { defineComponent } from 'vue';
import PopupContainer from 'pk/components/Popup/PopupContainer.vue';
import { Sidebar, SidebarItem, NavBar } from 'vant';
import IndustryService from '@/service/Industry/IndustryService';
import Checkbox from 'pk/components/Form/components/Checkbox.vue';
export default defineComponent({
  components: {
    PopupContainer,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [NavBar.name]: NavBar,
    Checkbox
  },
  props: {
    popupService: {
      type: Object,
      required: true
    },
    popupParams: {
      type: Object,
      default: () => {
        return {
          title: '行业'
        };
      }
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeKey: 0,
      innerValue: this.value
    };
  },
  computed: {
    sidebarData() {
      return IndustryService.list.map(res => {
        return {
          id: res.id,
          display_name: res.display_name,
          badge: this.__getSidebarDataBadge(res.children)
        };
      });
    },
    panelData() {
      return (IndustryService.list[this.activeKey].children || []).map(res => {
        const ids = res.children.map(row => row.id);
        res.active = ids.every(id => this.innerValue.includes(id));
        return res;
      });
    }
  },
  methods: {
    handleLoad() {
      return IndustryService.index();
    },
    handleSelect(id) {
      if (this.innerValue.includes(id)) {
        this.innerValue.splice(this.innerValue.indexOf(id), 1);
      } else {
        this.innerValue.push(id);
      }
    },
    handleClickAllSelect(v) {
      const ids = v.children.map(res => res.id);
      if (v.active) {
        const diffItems = ids.filter(id => !this.innerValue.includes(id));
        this.innerValue = this.innerValue.concat(diffItems);
      } else {
        this.innerValue = this.innerValue.filter(id => !ids.includes(id));
      }
    },
    handleSubmit() {
      return this.popupService.success(this.innerValue);
    },
    handleCancel() {
      return this.popupService.cancel();
    },
    __getSidebarDataBadge(children) {
      const ids = children.reduce((acc, res) => {
        acc = acc.concat(res.children);
        return acc;
      }, []).map(res => res.id);
      const intersection = (a, b) => {
        const s = new Set(b);
        return a.filter(x => s.has(x));
      };
      return intersection(ids, this.innerValue).length || '';
    }
  }
});