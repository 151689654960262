import IOService from 'pk/service/IOService'
import UserService from './User/UserService'

class IOServiceFill {
  ignoreLoginUrls = ['/user/todayFirstLogin', '/notify/getUnreadCount']

  public init () {
    IOService.checkLogin = (url: string) => {
      UserService.logout()
      return Promise.resolve()
        .then(() => {
          if (!this.ignoreLoginUrls.includes(url)) {
            return UserService.login()
          }
        })
    }
  }
}

export default new IOServiceFill()
